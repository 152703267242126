import Error from "./Pages/Error";

function App() {
  return (
    <div className="App">
      <Error />
    </div>
  );
}

export default App;
