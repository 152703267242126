import React from 'react';
import '../Styles/Error.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Error = () => {
    return (
        <div className="construction">
            <div className="constructionCont">
                <h1 className="constructionTitle">Just a Moment...</h1>
                <p className="constructionText">Hi there! You've caught me in the middle of some creative updates. I'm working hard to enhance my portfolio to showcase my latest projects and thoughts. Your understanding is greatly appreciated. Do check back soon for new insights and creations!</p>
                <div className="loader">Contact me</div>
                <div className="socialLinks">
                    <a href="https://www.linkedin.com/in/hasala-abhilasha/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} className="socialIcon" />
                    </a>
                    <a href="https://github.com/HasalaAbhilasha" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faGithub} className="socialIcon" />
                    </a>
                    <a href="mailto:hasala200301abhilasha@gmail.com">
                        <FontAwesomeIcon icon={faEnvelope} className="socialIcon" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Error;
